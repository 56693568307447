import { useEffect, useState } from 'react';

const useMedian = () => {
  const [isMedian, setIsMedian] = useState(false);

  useEffect(() => {
    setIsMedian(navigator.userAgent.indexOf('median') > -1);
  }, [setIsMedian]);

  return {
    isMedian,
  };
};

export default useMedian;
